
<template>
    <div class="Mint">
        <div class="tabs">
            <div v-if="!mintLoading" class="center connectBoxW">
                <div @click="tabIndex = 0;getLevelMethod(0)">
                    <!-- <img v-if="tabIndex == 0" src="../assets/Mint/commoniconA.png" alt="">
                    <img v-else src="../assets/Mint/commoniconB.png" alt=""> -->
                    <div :class=" tabIndex == 0 ? 'imgDiv imgDivA' : 'imgDiv imgDivB'"></div>
                    <p :class="tabIndex == 0 ? '' : 'no'">Common Planet</p>
                </div>
                <div @click="tabIndex = 1;getLevelMethod(1)">
                    <!-- <img v-if="tabIndex == 1" src="../assets/Mint/uniqueiconA.png" alt="">
                    <img v-else src="../assets/Mint/uniqueiconB.png" alt=""> -->
                    <div :class=" tabIndex == 1 ? 'imgDiv imgDivA' : 'imgDiv imgDivB'"></div>
                    <p :class="tabIndex == 1 ? '' : 'no'">Unique Planet</p>
                </div>
                <div @click="tabIndex = 2;getLevelMethod(2)">
                    <!-- <img v-if="tabIndex == 2" src="../assets/Mint/exclusiveiconA.png" alt="">
                    <img v-else src="../assets/Mint/exclusiveiconB.png" alt=""> -->
                    <div :class=" tabIndex == 2 ? 'imgDiv imgDivA' : 'imgDiv imgDivB'"></div>
                    <p :class="tabIndex == 2 ? '' : 'no'">Exclusive Star Sign</p>
                </div>
            </div>
            <div v-else class="center connectBoxW">
                <div>
                    <div :class=" tabIndex == 0 ? 'imgDiv imgDivA' : 'imgDiv imgDivB'"></div>
                    <p :class="tabIndex == 0 ? '' : 'no'">Common Planet</p>
                </div>
                <div>
                    <div :class=" tabIndex == 1 ? 'imgDiv imgDivA' : 'imgDiv imgDivB'"></div>
                    <p :class="tabIndex == 1 ? '' : 'no'">Unique Planet</p>
                </div>
                <div>
                    <div :class=" tabIndex == 2 ? 'imgDiv imgDivA' : 'imgDiv imgDivB'"></div>
                    <p :class="tabIndex == 2 ? '' : 'no'">Exclusive Star Sign</p>
                </div>
            </div>
        </div>
        <div class="info">
            <div class="center connectBoxW">
                <div v-if="tabIndex == 0" class="level0Box">
                    <div class="box1">
                        <div class="left">
                            <div class="title">
                                <img src="../assets/Mint/commontitle.png" alt="">
                                <div>
                                    <h1>Common Planet</h1>
                                    <p>This minting needs to cost {{level0CostSPACE}} {{tokenSymbol}} or {{level0CostxSPACE}} {{xtokenSymbol}}.</p>
                                </div>
                            </div>
                            <div class="select">
                                <p class="tip">Please select the token you want to pay with.</p>
                                <div class="selectToken">
                                    <div :class="mintToken == 0 ? 'item itemA' :'item'" @click="mintToken = 0">
                                        <p class="tokenname">{{tokenSymbol}}</p>
                                        <p class="tokenbalance">
                                            <span>{{SPACEBalanceof}}</span>
                                            <span>Current balance</span>
                                        </p>
                                        <img v-if="mintToken == 0" src="../assets/Mint/select.png" alt="">
                                        <img v-else src="../assets/Mint/unselect.png" alt="">
                                    </div>
                                    <div :class="mintToken == 1 ? 'item itemA' :'item'" @click="mintToken = 1">
                                        <p class="tokenname">{{xtokenSymbol}}</p>
                                        <p class="tokenbalance">
                                            <span>{{xSPACEBalanceof}}</span>
                                            <span>Current balance</span>
                                        </p>
                                        <img v-if="mintToken == 1" src="../assets/Mint/select.png" alt="">
                                        <img v-else src="../assets/Mint/unselect.png" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="rules">
                                <p class="rulestitle">
                                    <img src="../assets/Mint/icon2.png" alt="">
                                    <span>RULES</span>
                                    <span class="border"></span>
                                </p>
                                <div class="rulesinfo">
                                    <p>Common Planet NFT Minting Rules:</p>
                                    <p>1. 10 types of Common Planet NFT are randomly minted by burning SPACE. 90% of the SPACE you spent will be burned directly, and 10% of that will be used for NFT Bonus and DAO Treasury.</p>
                                    <p>2. By minting Common Planet NFT, you can get a 105%~150% reward multiplier for staking. For instance, burn 3000 SPACE to mint a Common Planet NFT that gets a 1.25 multiplier, which is equivalent to 3750 SPACE when staking.</p>
                                    <p>3. Planet NFT is a pass in the SpaceFi ecosystem, containing lots of SPACE assets and different rights. <a href="https://docs.spacefi.io/products/nft-system" target="_blank" rel="noopener noreferrer">Learn about SpaceFi NFT Ecosystem.</a></p>
                                </div>
                            </div>
                        </div>
                        <div class="centerlabel">
                            <img src="../assets/Mint/icon1.png" alt="">
                            <img src="../assets/Mint/icon1.png" alt="">
                        </div>
                        <div class="right">
                            <div class="router">
                                <p>{{tokenSymbol}} OR {{xtokenSymbol}} coins</p>
                                <p>
                                    <span>To</span>
                                    <img src="../assets/Mint/to.png" alt="">
                                    <span style="opacity:0;">0</span>
                                </p>
                                <p>COMMON PLANET</p>
                            </div>
                            <div class="img">
                                <div class="cardimg">
                                    <img v-if="!nftClaim" src="../assets/Mint/commonbox.png" alt="">
                                    <img v-else :src="mintImgUrl" alt="" srcset="">
                                    <p v-if="mintLoading && mintSuccess" :style="{top:(nftClaim ? '78%' : '42%')}">{{nftClaim ? 'Successfully minted' : 'It’s mintting'}}</p>
                                </div>
                            </div>
                            <div class="btn">
                                <p v-if="!nftClaim && !mintLoading" class="mintbtn" @click="mint(0)">mint</p>
                                <p v-else-if="!nftClaim && mintLoading" class="mintingbtn">minting</p>
                                <p v-else class="claim" @click="claim(0)">claim</p>
                            </div>
                        </div>
                    </div>
                    <div class="box2"></div>
                </div>
                <div v-if="tabIndex == 1" class="level1Box">
                    <div class="box1">
                        <div class="left">
                            <div class="title">
                                <img src="../assets/Mint/uniquetitle.png" alt="">
                                <div>
                                    <h1>Unique Planet</h1>
                                    <p>This minting needs to cost {{level1CostNFTNum}} different types of common planet NFTs, click to select.</p>
                                </div>
                            </div>
                            <div class="select">
                                <p class="tip">Please select the token you want to pay with.</p>
                                <div class="selectNFT">
                                    <div class="searchNFT">
                                        <i class="el-icon-search"></i>
                                        <input type="text" placeholder="Search NFT">
                                    </div>
                                    <ul class="nftlist">
                                        <li class="nftitem" v-for="(item,index) in level1Arr" :key="index" @click="selectLevelNFT(item,1)">
                                            <img class="nftimg" :src="item.nftUrl" alt="">
                                            <img v-if="item.select" class="selectimg" src="../assets/Mint/select.png" alt="">
                                            <img v-else class="selectimg" src="../assets/Mint/unselect.png" alt="">
                                        </li>
                                        <li class="nftitem"></li>
                                        <li class="nftitem"></li>
                                        <li class="nftitem"></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="centerlabel">
                            <img src="../assets/Mint/icon1.png" alt="">
                            <img src="../assets/Mint/icon1.png" alt="">
                        </div>
                        <div class="right">
                            <div class="router">
                                <p>COMMON PLANET</p>
                                <p>
                                    <span>To</span>
                                    <img src="../assets/Mint/to.png" alt="">
                                    <span>{{level1CostNFTNum}}</span>
                                </p>
                                <p>UNIQUE PLANET</p>
                            </div>
                            <div class="uniqueimg">
                                <div class="smallimg">
                                    <ul>
                                        <li :class=" meltLevel1SmallClassArr[0].select ? 'bgimgA' : 'bgimgB'"></li>
                                        <li :class=" meltLevel1SmallClassArr[1].select ? 'bgimgA' : 'bgimgB'"></li>
                                        <li :class=" meltLevel1SmallClassArr[2].select ? 'bgimgA' : 'bgimgB'"></li>
                                        <li :class=" meltLevel1SmallClassArr[3].select ? 'bgimgA' : 'bgimgB'"></li>
                                        <li :class=" meltLevel1SmallClassArr[4].select ? 'bgimgA' : 'bgimgB'"></li>
                                        <li :class=" meltLevel1SmallClassArr[5].select ? 'bgimgA' : 'bgimgB'"></li>
                                        <li :class=" meltLevel1SmallClassArr[6].select ? 'bgimgA' : 'bgimgB'"></li>
                                        <li :class=" meltLevel1SmallClassArr[7].select ? 'bgimgA' : 'bgimgB'"></li>
                                        <li :class=" meltLevel1SmallClassArr[8].select ? 'bgimgA' : 'bgimgB'"></li>
                                        <li :class=" meltLevel1SmallClassArr[9].select ? 'bgimgA' : 'bgimgB'"></li>
                                    </ul>
                                    <p><span>{{meltSmallClassNum}}</span>/10</p>
                                </div>
                                <div class="cardimg">
                                    <img v-if="!nftClaim"  src="../assets/Mint/uniquebox.png" alt="">
                                    <img v-else :src="mintImgUrl" alt="" srcset="">
                                    <p v-if="mintLoading && mintSuccess" :style="{transform: 'translateX(0) translateY(-50%)',top:(nftClaim ? '83%' : '36%')}">{{nftClaim ? 'Successfully minted' : 'It’s mintting'}}</p>
                                </div>
                            </div>
                            <div class="btn">
                                <p v-if="!nftClaim && !mintLoading" class="mintbtn" @click="mint(1)">mint</p>
                                <p v-else-if="!nftClaim && mintLoading" class="mintingbtn">minting</p>
                                <p v-else class="claim" @click="claim(1)">claim</p>
                            </div>
                        </div>
                    </div>
                    <div class="box2">
                        <div class="rules">
                            <p class="rulestitle">
                                <img src="../assets/Mint/icon2.png" alt="">
                                <span>RULES</span>
                                <span class="border"></span>
                            </p>
                            <div class="rulesinfo">
                                <h1>Unique Planet NFT Minting Rules:</h1>
                                <div>
                                    <div>
                                        <p>1. 10 types of Unique Planet NFT, limited to 500 of each. Currently, 9 different Common Planet NFT can burned to mint a Unique Planet NFT randomly.</p>
                                        <p>2. By minting the Unique Planet NFT, you can get a 105%~150% reward multiplier for staking. For instance, if 5 Common Planet NFT are burned with the original multipliers being 1.25x, 1.2x, 1.1x, 1.5x and 1.2x, the multiplier of the minted Unique Planet NFT being 1.4x, then the finally obtained multipliers will be = (1.25+ 1.2+1.1+1.5+1.2) / 5 * 1.4=1.75.</p>
                                    </div>
                                    <div>
                                        <p>3. Planet NFT is a pass in the SpaceFi ecosystem, containing lots of SPACE assets and different rights. <a href="https://docs.spacefi.io/products/nft-system" target="_blank" rel="noopener noreferrer">Learn about SpaceFi NFT Ecosystem.</a></p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="tabIndex == 2" class="level2Box">
                    <div class="box1">
                        <div class="left">
                            <div class="title">
                                <img src="../assets/Mint/exclusivetitle.png" alt="">
                                <div>
                                    <h1>Exclusive Star Sign</h1>
                                    <p>This minting needs to cost {{level2CostNFTNum}} different types of unique planet NFTs, click to select.</p>
                                </div>
                            </div>
                            <div class="select">
                                <p class="tip">Please select the token you want to pay with.</p>
                                <div class="selectNFT">
                                    <div class="searchNFT">
                                        <i class="el-icon-search"></i>
                                        <input type="text" placeholder="Search NFT">
                                    </div>
                                    <ul class="nftlist">
                                        <li class="nftitem" v-for="(item,index) in level2Arr" :key="index" @click="selectLevelNFT(item,2)">
                                            <img class="nftimg" :src="item.nftUrl" alt="">
                                            <img v-if="item.select" class="selectimg" src="../assets/Mint/select.png" alt="">
                                            <img v-else class="selectimg" src="../assets/Mint/unselect.png" alt="">
                                        </li>
                                        <li class="nftitem"></li>
                                        <li class="nftitem"></li>
                                        <li class="nftitem"></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="centerlabel">
                            <img src="../assets/Mint/icon1.png" alt="">
                            <img src="../assets/Mint/icon1.png" alt="">
                        </div>
                        <div class="right">
                            <div class="router">
                                <p>UNIQUE PLANET</p>
                                <p>
                                    <span>To</span>
                                    <img src="../assets/Mint/to.png" alt="">
                                    <span>{{level2CostNFTNum}}</span>
                                </p>
                                <p>EXCLUSIVE STAR SIGN</p>
                            </div>
                            <div class="exclusiveimg">
                                <div class="smallimg">
                                    <ul>
                                        <li :class=" meltLevel2SmallClassArr[0].select ? 'bgimgA' : 'bgimgB'"></li>
                                        <li :class=" meltLevel2SmallClassArr[1].select ? 'bgimgA' : 'bgimgB'"></li>
                                        <li :class=" meltLevel2SmallClassArr[2].select ? 'bgimgA' : 'bgimgB'"></li>
                                        <li :class=" meltLevel2SmallClassArr[3].select ? 'bgimgA' : 'bgimgB'"></li>
                                        <li :class=" meltLevel2SmallClassArr[4].select ? 'bgimgA' : 'bgimgB'"></li>
                                        <li :class=" meltLevel2SmallClassArr[5].select ? 'bgimgA' : 'bgimgB'"></li>
                                        <li :class=" meltLevel2SmallClassArr[6].select ? 'bgimgA' : 'bgimgB'"></li>
                                        <li :class=" meltLevel2SmallClassArr[7].select ? 'bgimgA' : 'bgimgB'"></li>
                                        <li :class=" meltLevel2SmallClassArr[8].select ? 'bgimgA' : 'bgimgB'"></li>
                                        <li :class=" meltLevel2SmallClassArr[9].select ? 'bgimgA' : 'bgimgB'"></li>
                                    </ul>
                                    <p><span>{{meltSmallClassNum}}</span>/10</p>
                                </div>

                                <div class="cardimg">
                                    <img v-if="!nftClaim"  src="../assets/Mint/exclusivebox.png" alt="">
                                    <img v-else :src="mintImgUrl" alt="" srcset="">
                                    <p v-if="mintLoading && mintSuccess" :style="{transform: 'translateX(0) translateY(-50%)',top:(nftClaim ? '79%' : '36%')}">{{nftClaim ? 'Successfully minted' : 'It’s mintting'}}</p>
                                </div>
                            </div>
                            <div class="btn">
                                <p v-if="!nftClaim && !mintLoading" class="mintbtn" @click="mint(2)">mint</p>
                                <p v-else-if="!nftClaim && mintLoading" class="mintingbtn">minting</p>
                                <p v-else class="claim" @click="claim(2)">claim</p>
                            </div>
                        </div>
                    </div>
                    <div class="box2">
                        <div class="rules">
                            <p class="rulestitle">
                                <img src="../assets/Mint/icon2.png" alt="">
                                <span>RULES</span>
                                <span class="border"></span>
                            </p>
                            <div class="rulesinfo">
                                <h1>Exclusive Star Sign NFT Minting rules:</h1>
                                <div>
                                    <div>
                                        <p>1. There're 12 types of Exclusive Star Sign NFT, each of which is limited to 1, 12 in total. 5~10 different Unique Planet NFT can be burned to mint a Exclusive Star Sign NFT randomly.</p>
                                        <p>
                                            2. By minting the Exclusive Star Sign NFT, you can get a reward multiplier on the basis of original Unique Planet NFT, and the calculation method is similar. The multipliers of Exclusive Star Sign NFT:
                                            <br />
                                            No. 1-2 Star Sign, 2X, needs 5 different types of Unique Planet NFT to mint;
                                            <br />
                                            No. 3-4 Star Sign, 3X, needs 6 different types of Unique Planet NFT to mint;
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            No. 5-6 Star Sign, 4X, needs 7 different types of Unique Planet NFT to mint;
                                            <br />
                                            No. 7-8 Star Sign, 5X, needs 8 different types of Unique Planet NFT to mint;
                                            <br />
                                            No. 9-10 Star Sign, 6X, needs 9 different types of Unique Planet NFT to mint;
                                            <br />
                                            No. 11-12 Star Sign, 7X, needs 10 different types of Unique Planet NFT to mint.
                                        </p>
                                        <p>3. Planet NFT is a pass in the SpaceFi ecosystem, containing lots of SPACE assets and different rights. <a href="https://docs.spacefi.io/products/nft-system" target="_blank" rel="noopener noreferrer">Learn about SpaceFi NFT Ecosystem.</a></p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <v-Footer></v-Footer>
    </div>
</template>

<script>
import {
    judgeNetwork,getAllowance,setApprove,
    getBalanceOf,getxstarBalanceof,levelMethod,Xprice,
    nftBalanceOf,tokenOfOwnerByIndex,starMeta,cateURI,
    mint,melt
} from '../utils/space.js'
export default {
    name: "Mint",
    data() {
        return {
            userAddress:'',tokenSymbol:'',xtokenSymbol:'',nftInfoList:[],tokenAddress:'',xtokenAddress:'',nftAddress:'',
            tabIndex: 0,mintToken:0,
            level1Arr:[],level2Arr:[],
            meltArr:[],meltSmallClassNum:0,
            meltLevel1SmallClassArr:[
                {name:'Meteorite',select:false,id:0},
                {name:'Meteor',select:false,id:0},
                {name:'Comet',select:false,id:0},
                {name:'Asteroid',select:false,id:0},
                {name:'Satellite',select:false,id:0},
                {name:'Planet',select:false,id:0},
                {name:'Stellar',select:false,id:0},
                {name:'Black hole',select:false,id:0},
                {name:'Nebula',select:false,id:0},
                {name:'Galaxy',select:false,id:0}
            ],
            meltLevel2SmallClassArr:[
                {name:'Sun',select:false,id:0},
                {name:'Mercury',select:false,id:0},
                {name:'Venus',select:false,id:0},
                {name:'Earth',select:false,id:0},
                {name:'Mars',select:false,id:0},
                {name:'Jupiter',select:false,id:0},
                {name:'Saturn',select:false,id:0},
                {name:'Uranus',select:false,id:0},
                {name:'Neptune',select:false,id:0},
                {name:'Moon',select:false,id:0},
            ],
            SPACEBalanceof:0,xSPACEBalanceof:0,
            level0CostSPACE:0,level0CostxSPACE:0,level1CostNFTNum:0,level2CostNFTNum:0,
            mintLoading:false,nftClaim:false,mintSuccess:true,mintImgUrl:'',
        }
    },
    mounted() { 
        this.userAddress = localStorage.getItem('userAddress')
        this.tokenSymbol = localStorage.getItem('tokenSymbol')
        this.xtokenSymbol = localStorage.getItem('xtokenSymbol')
        this.nftInfoList = window.nftImgData.cardList
        this.tokenAddress = window.contractAddr.tokenAddress
        this.xTokenAddress = window.contractAddr.xTokenAddress
        this.nftAddress = window.contractAddr.nftAddress
        this.logicalAddress = window.contractAddr.logicalAddress
        this.mintToken = !localStorage.getItem(`${this.userAddress}mintToken`) ? 0 : JSON.parse(localStorage.getItem(`${this.userAddress}mintToken`))
        this.getLevelMethod(0)
        if (this.userAddress) {
            this.getBalanceData()
            this.getNFTBalance()
        }
    },
    methods: {
        selectLevelNFT(item,level){
            if (level == 1) {
                if (this.meltSmallClassNum == this.level1CostNFTNum && !item.select) {
                    this.$message({type:"warning",message:"This minting needs to cost "+this.level1CostNFTNum+" different types of common planet NFTs"})
                    return
                }
                this.meltLevel1SmallClassArr.forEach((smallItem,smallItndex) => {
                    if (item.name == smallItem.name && !smallItem.select && smallItem.id == 0) {
                        item.select = true
                        smallItem.select = true
                        smallItem.id = item.NFTId
                        this.meltArr.push(item.NFTId)
                        this.meltSmallClassNum ++
                        return
                    }
                    if (item.name == smallItem.name && item.NFTId == smallItem.id && smallItem.select) {
                        item.select = false
                        smallItem.select = false
                        smallItem.id = 0
                        for (let i = 0; i < this.meltArr.length; i++) {
                            if (this.meltArr[i] == item.NFTId) {
                                this.meltArr.splice(i,1)
                            }
                        }
                        this.meltSmallClassNum --
                        return
                    }
                })
            }
            if (level == 2) {
                if (this.meltSmallClassNum == this.level2CostNFTNum && !item.select) {
                    this.$message({type:"warning",message:"This minting needs to cost "+this.level2CostNFTNum+" different types of common planet NFTs"})
                    return
                }
                this.meltLevel2SmallClassArr.forEach((smallItem,smallItndex) => {
                    if (item.name == smallItem.name && !smallItem.select && smallItem.id == 0) {
                        item.select = true
                        smallItem.select = true
                        smallItem.id = item.NFTId
                        this.meltArr.push(item.NFTId)
                        this.meltSmallClassNum ++
                        return
                    }
                    if (item.name == smallItem.name && item.NFTId == smallItem.id && smallItem.select) {
                        item.select = false
                        smallItem.select = false
                        smallItem.id = 0
                        for (let i = 0; i < this.meltArr.length; i++) {
                            if (this.meltArr[i] == item.NFTId) {
                                this.meltArr.splice(i,1)
                            }
                        }
                        this.meltSmallClassNum --
                        return
                    }
                })
            }
            
        },
        mint(level){
            if (this.userAddress == '') {
                this.$notify({type:'warning',message:'Please connect wallet first!'})
                return false
            }
            if (this.mintLoading) {
                return false
            }
            localStorage.setItem(`${this.userAddress}mintToken` ,JSON.stringify(this.mintToken))
            this.mintLoading = true
            this.mintSuccess = true
            judgeNetwork().then(res => {
                if (res == 0) {
                    this.mintLoading = false
                    return false
                }
                if (level == 0){
                    if (this.mintToken == 0 && Number(this.SPACEBalanceof) < Number(this.level0CostSPACE) || this.mintToken == 1 && Number(this.xSPACEBalanceof) < Number(this.level0CostxSPACE)) {
                        this.$message({ message:'Insufficient Balance', type:'warning' })
                        this.mintLoading = false
                        return false
                    }
                    getAllowance(this.mintToken == 0 ? this.tokenAddress : this.xTokenAddress,this.logicalAddress,this.userAddress).then(allowanceNum => {
                        var allowanceNum = allowanceNum/1e18
                        if (this.mintToken == 0 && Number(allowanceNum) < Number(this.level0CostSPACE) || this.mintToken == 1 && Number(allowanceNum) < Number(this.level0CostxSPACE)) {
                            setApprove(this.mintToken == 0 ? this.tokenAddress : this.xTokenAddress,this.logicalAddress,this.userAddress).then(res => {
                                this.$message({ type: 'success', message: 'Approve Success' });
                                this.mintStar(level)
                            }).catch(error => {
                                this.mintLoading = false
                            })
                        } else {
                            this.mintStar(level)
                        }
                        
                    })
                } else if (level == 1) {
                    //console.log(this.meltArr)
                    if (this.meltArr.length != this.level1CostNFTNum) {
                        this.$message({type:"warning",message:"This minting needs to cost "+this.level1CostNFTNum+" different types of common planet NFTs"})
                        this.mintLoading = false
                        return
                    } else {
                        this.mintStar(level)
                    }
                } else if (level == 2) {
                    //console.log(this.meltArr)
                    if (this.meltArr.length != this.level2CostNFTNum) {
                        this.$message({type:"warning",message:"This minting needs to cost "+this.level2CostNFTNum+" different types of common planet NFTs"})
                        this.mintLoading = false
                        return
                    } else {
                        this.mintStar(level)
                    }
                }
            })
        },
        mintStar(level){
            //console.log(level)
            if (level == 0) {
                mint(this.logicalAddress,this.mintToken,this.userAddress).then(res => {
                    this.mintUrl()
                }).catch(error => {
                    this.$message({ type: 'warning', message: 'NFT Minting Failed' });
                    this.mintLoading = false
                })
            } else {
                melt(this.logicalAddress,this.meltArr,level,this.userAddress).then(res => {
                    this.mintUrl()
                }).catch(error => {
                    this.$message({ type: 'warning', message: 'NFT Minting Failed' });
                    this.mintLoading = false
                })
            }
            
        },
        mintUrl(){
            nftBalanceOf(this.nftAddress,this.userAddress).then(nftBalanceOf => {
                //console.log(nftBalanceOf)
                tokenOfOwnerByIndex(this.nftAddress,nftBalanceOf-1,this.userAddress).then(nftIndex => {
                    //console.log(nftIndex)
                    cateURI(this.nftAddress,nftIndex).then(nftUrl => {
                        this.mintImgUrl = nftUrl
                        this.nftClaim = true
                        setTimeout(() => {
                            this.mintSuccess = false
                        }, 4000);
                    })
                })
            })
        },
        claim(){
            this.meltArr = []
            this.meltSmallClassNum = 0
            this.meltLevel1SmallClassArr.forEach(item => {item.select = false;item.id = 0;})
            this.meltLevel2SmallClassArr.forEach(item => {item.select = false;item.id = 0;})
            this.nftClaim = false
            this.mintLoading = false
            this.getBalanceData()
            this.getNFTBalance()
            this.getLevelMethod(this.tabIndex)
        },
        getLevelMethod(level){
            this.meltArr = []
            this.meltSmallClassNum = 0
            this.level1Arr.forEach(item => {item.select = false})
            this.level2Arr.forEach(item => {item.select = false})
            this.meltLevel1SmallClassArr.forEach(item => {item.select = false;item.id = 0;})
            this.meltLevel2SmallClassArr.forEach(item => {item.select = false;item.id = 0;})
            levelMethod(this.logicalAddress,level).then(levelMethodRes => {
                if (level == 0) {
                    this.level0CostSPACE = levelMethodRes.price/Math.pow(10,18)
                    Xprice(this.logicalAddress).then(XpriceRes => {
                        this.level0CostxSPACE = XpriceRes/Math.pow(10,18)
                    })
                } else {
                    if (!levelMethodRes.isInc) {
                        if (level == 1){ this.level1CostNFTNum = levelMethodRes.price }
                        if (level == 2){ this.level2CostNFTNum = levelMethodRes.price }
                    } else {
                        if (level == 1){ this.level1CostNFTNum = levelMethodRes.price-0+(Math.floor(levelMethodRes.usedUnit/levelMethodRes.base)*levelMethodRes.step) }
                        if (level == 2){ this.level2CostNFTNum = levelMethodRes.price-0+(Math.floor(levelMethodRes.usedUnit/levelMethodRes.base)*levelMethodRes.step) }
                    }
                }
            })
        },
        getBalanceData(){
            getBalanceOf(this.tokenAddress,this.userAddress).then(res => {
                if (res == 0) {
                    this.SPACEBalanceof = 0
                } else {
                    this.SPACEBalanceof = res.slice(0,-18)
                }
            })
            getxstarBalanceof(this.xTokenAddress,this.userAddress).then(res => {
                if (res == 0) {
                    this.xSPACEBalanceof = 0
                } else {
                    this.xSPACEBalanceof = res.slice(0,-18)
                }
                
            })
        },
        getNFTBalance(){
            this.level1Arr = []
            this.level2Arr = []
            nftBalanceOf(this.nftAddress,this.userAddress).then(nftBalanceOf => {
                for (let i = 0; i < nftBalanceOf; i++) {
                    tokenOfOwnerByIndex(this.nftAddress,i,this.userAddress).then(nftIndex => {
                        starMeta(this.logicalAddress,nftIndex).then(nftInfo => {
                            cateURI(this.nftAddress,nftIndex).then(nftUrl => {
                                var obj = {
                                    nftUrl,
                                    NFTId:Number(nftIndex),
                                    select:false,
                                    name:''
                                }
                                this.nftInfoList.forEach((item,index) => {
                                    if (item.cid == nftUrl.split('/')[3]) {
                                        obj.name = item.name
                                    }
                                })
                                if (nftInfo.level == 0) {
                                    this.level1Arr.push(obj)
                                    let arr1 = this.level1Arr
                                    this.dataSort(arr1)
                                }
                                if (nftInfo.level == 1) {
                                    this.level2Arr.push(obj)
                                    let arr2 =  this.level2Arr
                                    this.dataSort(arr2)
                                }
                            })
                        })
                        
                    })
                }
                
            })
        },
        dataSort(arr){
            arr.sort((a ,b) => {
                if (a.NFTId > b.NFTId) {
                    return -1
                } else if (a.NFTId < b.NFTId) {
                    return 1
                }
            })
        },
        
    }
}
</script>